import * as Constants from '../constants'
import cookies from 'react-cookies'
import { API_LOGIN, API_REGISTER } from "../urls";
import config from "../local_config"

export function loginPost(data) {
    return Constants.apiAction({
        url: API_LOGIN,
        method: Constants.REQUEST_POST,
        data: data,
        onSuccess: loginSuccess,
        onFailure: loginFailure,
        label: Constants.LOGIN_REQUESTED,
    })
}

function loginSuccess(data) {
    cookies.save(Constants.USER_DATA, JSON.stringify(data), { domain: config.domainName });
    cookies.save(Constants.ACCESS_TOKEN, data.token, { domain: config.domainName });
    return {
        type: Constants.LOGIN_SUCCESS,
        payload: data
    }
}
export function isLoggedIn(data) {
    return {
        type: Constants.LOGIN_SUCCESS,
        payload: data
    }
}
function loginFailure(data) {
    cookies.remove(Constants.USER_DATA, { domain: config.domainName })
    cookies.remove(Constants.ACCESS_TOKEN, { domain: config.domainName });
    let errorMsg = ''
    if (data && data.response && data.response.data.detail) {
        errorMsg = data.response.data.detail
    } else {
        errorMsg = data.message
    }
    return {
        type: Constants.LOGIN_FAILED,
        payload: errorMsg
    };
}


export function logoutAction(router = '') {
    cookies.remove(Constants.USER_DATA, { domain: config.domainName });
    cookies.remove(Constants.ACCESS_TOKEN, { domain: config.domainName });
    if (router !== '') {
        router.push("/login");
    }
    return {
        type: Constants.LOGOUT_SUCCESS,
        payload: router !== '' ? 'Logged out successfully.' : ''
    }

}


export function registerUser(data, router) {
    return Constants.apiAction({
        url: API_REGISTER,
        method: Constants.REQUEST_POST,
        data: data,
        onSuccess: onRegisterUserSuccess,
        onFailure: onRegisterUserFailure,
        label: Constants.REGISTRATION_REQUESTED,
        router: router
    })
}

function onRegisterUserSuccess(data, router) {
    console.log(data)
    router.push("/login");
    return {
        type: Constants.REGISTRATION_SUCCESS,
        payload: data.message
    }
}

function onRegisterUserFailure(data) {
    console.log(data)
    return {
        type: Constants.REGISTRATION_FAILED,
        payload: data.response.data.non_field_errors[0]
    }
}

